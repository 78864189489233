<template>
  <div id="app">
    <header id="header">
      <nav class="header_menu">
        <div class="container">
          <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar first"></span>
              <span class="icon-bar second"></span>
              <span class="icon-bar third"></span>
            </button>
            <a class="navbar-brand" href="/"><div class="logoFont">Marc Wiese</div></a>
          </div>
          <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav">
              <li><a class="page-scroll" href="#header">Home</a></li>
              <li><a class="page-scroll" href="#about">About Me</a></li>
              <li><a class="page-scroll" href="#resume">Resume</a></li>
              <li><a class="page-scroll" href="#contact">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    <router-view/>
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i%7CPoppins:300,400,500,600,700');
@import "assets/flat_icon/flaticon.css";
@import "assets/css/font-awesome.min.css";
@import "assets/css/bootstrap.min.css";
@import "assets/css/lightcase.css";
@import "assets/css/slick.css";
@import "assets/css/slick-theme.css";
@import "assets/css/style.css";
@import "assets/css/responsive.css";


.logoFont
{
  font-weight: bold;
  font-family: 'Dela Gothic One', cursive;
  font-size: 36px;
  color: #000;
}

</style>
<script setup>
</script>