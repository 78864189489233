<template>
  <div>
    <div class="logoFont">Marc Wiese</div>
    <h1>About Page</h1>
    <p>This is the About page.</p>
  </div>
</template>

<script>
export default {
  name: "AboutView"
};
</script>
