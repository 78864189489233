<template>
  <div>
    <div class="logoFont">Marc Wiese</div>
    <h1>Contact Page</h1>
    <p>Get in touch with us through the Contact page.</p>
  </div>
</template>

<script>
export default {
  name: "ContactView"
};
</script>
