<template>
  <section id="banner" class="first-banner">
    <div class="banner">
      <div class="container">
        <div class="banner_content">
          <h4>Welcome! I'm</h4>
          <h2>Marc Wiese</h2>
          <p>IT Professional</p>
          <ul class="banner_social">
            <li><a href="https://www.linkedin.com/in/mrwiese/"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>


  <!-- About section start here -->
  <section id="about" class="about padding_140">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div class="section_header">
            <h2>Who am I ?</h2>
          </div>
          <div class="about_details">
            <p>As a consultant I have learned to perform from Day 1, which reduce costs for my clients and make them able to give me tasks faster. I have the last 8 years successfully streamlining workflows, reducing inefficiencies, and aligning technology solutions with business objectives.</p>
            <p>My background is strong in process optimization, system architecture, and digital transformation. Proven expertise in guiding organizations through legacy system migrations to modern platforms. Helping leading and guiding teams, developing innovative software solutions, and delivering measurable results.</p>
            <ul>
              <li><p>Name<span>:</span></p> <span>Marc Wiese</span></li>
              <li><p>Phone<span>:</span></p> <span><a href="tel:+17472268663">+1 (747) 226-8663</a></span></li>
              <li><p>E-mail<span>:</span></p> <span><a href="mailto:mr@marcwiese.com">mr@marcwiese.com</a></span></li>
              <li><p>Address<span>:</span></p> <span>Where the jobs take me</span></li>
              <li><p>Date of Birth<span>:</span></p> <span>01 February 1980</span></li>
              <li><p>Website<span>:</span></p> <span>www.marcwiese.com</span></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div class="about_job">
            <h3>What can i Do</h3>
            <ul>
              <li><span class="icon flaticon-layers-icon"></span><p>Full-Stack Development</p></li>
              <li><span class="icon flaticon-business-affiliate-network"></span><p>Integrations</p></li>
              <li><span class="icon flaticon-responsive-design-symbol"></span><p>Project<br />Management</p></li>
            </ul>
          </div>

          <div class="about_skills">
            <h3>Business Skills</h3>
            <div class="skills_progress">
              <div class="progress_item"><p class="progress_title">Business Process</p></div>
              <div class="progress_item"><p class="progress_title">Optimization using IT</p></div>
              <div class="progress_item"><p class="progress_title">Fast To Market</p></div>
              <div class="progress_item"><p class="progress_title">Integrations between IT and Business</p></div>
              <div class="progress_item"><p class="progress_title">Finance / Integrations</p></div>
              <div class="progress_item"><p class="progress_title">Getting Fast To Market</p></div>
              <div class="progress_item"><p class="progress_title">Getting Fast To Market</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



  <!-- Resume section start here -->
  <section id="resume" class="resume padding_140 bg_border">
    <div class="container">
      <div class="section_header">
        <h2>Resume</h2>
        <p><em>Achievements, Expertise, and Passion: Unveil My Professional Journey Through My Resume.</em></p>
      </div>

      <div class="resume_items">
        <div class="row">
          <div class="col-md-6 col-sm-12 col-xs-12">
            <h3>Lastest Certificates</h3>
            <div class="experiences left">
              <div class="experience">
                <h4> S4D400</h4>
                <p><span>Jul 2019</span></p>
                <p class="ex_details">Introduction to ABAP Programming for SAP S/4HANA</p>
              </div>
              <div class="experience">
                <h4>ITIL Foundation</h4>
                <p><span>Oct 2017</span></p>
                <p class="ex_details">AXELOS Global Best Practice</p>
              </div>
              <div class="experience">
                <h4>Prince2</h4>
                <p><span>Sep 2017</span></p>
                <p class="ex_details">AXELOS Global Best PracticeAXELOS</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12">
            <h3>Working Experience</h3>
            <div class="experiences right">
              <div class="experience">
                <h4>Technology Consultant</h4>
                <p>Hello Blossom / <span>Jan 2021 - </span></p>
                <p class="ex_details">
                  Working as an IT Consultant, helping maintain several companies it systems, helping them migrate them, or build completely new ones.
                </p>
              </div>
              <div class="experience">
                <h4>Application Engineer</h4>
                <p>LEGO Systems / <span>April 2019 - Dec 2020</span></p>
                <p class="ex_details">
                  Building Demand Planning Tools for global demand planners.
                  Used AWS, Python and Big Data / Hadoop, but change enviroment to SAP Cloud.</p>
              </div>
              <div class="experience">
                <h4>Java Developer - Contract</h4>
                <p>TV 2 DANMARK A/S / <span>Jun 2018 - Feb 2019</span></p>
                <p class="ex_details">
                  Was part of team that maintained and upgrade legacy code to new and more effective code. Most of these system were restful api's.
                </p>
              </div>
              <div class="experience">
                <p class="ex_details">
                  For many more look at my <a href="https://www.linkedin.com/in/mrwiese/">LinkedIn</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section id="contact" class="contact padding_140 bg_border">
    <div class="container">
      <div class="section_header">
        <h2>Get in Touch</h2>
        <p><em>I believe in the power of collaboration and mutual growth. Let's start a conversation about your business challenges and goals. Together, we can explore innovative strategies and unlock new opportunities. Share your thoughts below, and let's get started.
        </em></p>
      </div>
      <div class="contact_me">
        <div class="contact_info">
          <div class="contact_item">
            <div class="contact_icon flaticon-telephone-of-old-design"></div>
            <div class="contact_details">
              <br />
              <p><a href="tel:+17472268663">+1 (747) 226-8663</a> </p>
              <br />
            </div>
          </div>
          <div class="contact_item">
            <div class="contact_icon flaticon-multimedia"></div>
            <div class="contact_details">
              <br />
              <p><a href="mailto:mr@marcwiese.com">mr@marcwiese.com</a></p>
              <br />
            </div>
          </div>
          <div class="contact_item">
            <div class="contact_icon flaticon-home"></div>
            <div class="contact_details">
              <p>Pedroso, Porto, Portugal</p>
              <p>Montreal, QC, Canada </p>
              <p>Jersey City, NJ, USA</p>
            </div>
          </div>
        </div>
        <form>
          <input type="text" name="name" placeholder="Name:" class="contact_input width_h" disabled>
          <input type="email" name="email" placeholder="Email:" class="contact_input width_h" disabled>
          <input type="text" name="subject" placeholder="Subject:" class="contact_input" disabled>
          <textarea rows="5" class="contact_input" disabled>Messege</textarea>
          <input type="submit" name="subit" value="Submit" class="contact_submit" disabled>
        </form>
      </div>
    </div>
  </section>

  <footer>
    <p>&#169; copyright <a href="#">Marc Wiese</a> 2023. All Right Reserved by <a href="/">Marc Wiese</a></p>
  </footer>


</template>

<script>
export default {
name: "HomeView"
};
</script>
